/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import { Fragment, useEffect } from "react";
import { Box, Flex, Heading, Text } from "rebass";
import Hero from "../../../components/Hero";
import ProjectFadeInTitle from "../../../components/ProjectFadeInTitle";
import ProjectTitleListing from "../../../components/ProjectTitleListing";
import BackToTop from "../../../components/BackToTop";
//import IntroImg from "../../../img/oberon/trabelbase-cover.jpg";
import PlaneScene from "../../../components/PlaneScene";
import { StaticImage } from "gatsby-plugin-image";
import CompanyProjects from "../../../components/CompanyProjects";
import Layout from "../../../layouts/";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import ThemeColorSetter from "../../../components/ThemeColorSetter";

import Carousel from "../../../components/Carousel";
import img1 from "../../../img/oberon/oberon-travelbase-ds-1.jpg";
import img2 from "../../../img/oberon/oberon-travelbase-ds-2.jpg";
import img3 from "../../../img/oberon/oberon-travelbase-ds-3.jpg";
import img4 from "../../../img/oberon/oberon-travelbase-ds-4.jpg";
import img5 from "../../../img/oberon/oberon-travelbase-ds-5.jpg";

function ProjectTest() {
  const context = useThemeUI();
  const { theme } = context;

  const projectBgColor = theme?.colors?.oberonProjectBgTravelbase as string;
  const projectTextColor = theme?.colors?.oberonProjectTextTravelbase as string;
  const themeColor = theme?.colors?.oberonProjectBgTravelbase as string;
  const themeColorBg = theme?.colors?.background as string;

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById("hero-section");
      if (heroSection) {
        const rect = heroSection.getBoundingClientRect();
        if (rect.bottom > 0) {
          document.body.classList.add("hero-visible");
          document.body.classList.remove("hero-hidden");
        } else {
          document.body.classList.add("hero-hidden");
          document.body.classList.remove("hero-visible");
        }
      } else {
        document.body.classList.add("hero-hidden");
        document.body.classList.remove("hero-visible");
      }
    };

    const heroSection = document.getElementById("hero-section");
    if (heroSection) {
      window.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      document.body.classList.add("hero-hidden");
      document.body.classList.remove("hero-visible");
    }
  }, []);

  return (
    <Layout>
      <ThemeColorSetter color={themeColor} colorBg={themeColorBg} />
      <main>
        <Header bgColor={projectBgColor} textColor={projectTextColor} />
        <Hero
          projectType={"Showcase"}
          title={"Travelbase"}
          titleRow={"Platform"}
          titleClassName={"uppercase"}
          subtitle={"One-stop-shop booking platform for regional tourism"}
          productType={"Booking System, SaaS Website, Multi-brand, Design System"}
          projectBg={projectBgColor}
          projectText={projectTextColor}
          shortDesc={
            <Fragment>
              A platforms that allows tourists to find and book accommodation
              and activities for their holidays or days out. The information,
              prices and availability of the accommodation and activities are
              managed and adjusted by businesses within the region.
            </Fragment>
          }
          year={"2021"}
          role={
            <Fragment>
              Product Design <br /> UI Engineer
            </Fragment>
          }
          company={"OBERON"}
          roleDesc={
            <Fragment>
              As a member of the Design and Front-end teams, I actively
              contributed to daily operations aimed at improving user experience
              across diverse client projects, from initial design concepts to
              front-end execution.
            </Fragment>
          }
          disciplines={<Fragment>Design &mdash; Development</Fragment>}
          challenge={
            <Fragment>
              Achieving a scalable and future-proof design while working within
              budget and strict deadlines on an end-to-end project.
            </Fragment>
          }
          //img={IntroImg}
          component={
            <PlaneScene
              coverColor={"#f0c844"}
              textColor={"silver"}
              urlTexture={"/models/textures/oberon-cover-travelbase.jpg"}
              urlBackTexture={"/models/textures/oberon-cover-travelbase-back.jpg"}
            />
          }
        />

        <Flex
          flexWrap="wrap"
          justifyContent="center"
          variant="container"
          id="project-content"
        >
          <Box width={[1 / 1, 12 / 12]} variant="containerBigTitle">
            <ProjectFadeInTitle
              word1={"Consolidating"}
              word1ClassName={"outline"}
              word2={"styled"}
              className={"uppercase"}
              word3={"components"}
              word3ClassName={"uppercase"}
            />
          </Box>
          <Box variant="containerTxt">
            <Heading as="h3" variant="catHeading">
              Oportunity
            </Heading>
            <Text as="p" variant="paragraphXL" mb={[4]}>
              Travelbase needs a way to manage the increased number of brands
              adopting the system because the current approach of customizing
              the styling for each brand has become costly, even with a design
              system and component-based setup.
            </Text>
            <Heading as="h3" variant="catHeading">
              Our goal
            </Heading>
            <Text as="p" mb={[4, 5, 6]}>
              With more customers accessing the platform from mobile devices, it
              was imperative to adopt a mobile-first mentality. We needed an{" "}
              <strong>
                improved and simpler way to manage changes without increasing
                costs for our clients
              </strong>
              .
            </Text>
            <Heading as="h3" variant="catHeading">
              UI Overhaul
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              Reduce moving parts
            </Heading>
            <Text as="p">
              I've improved the setup by reducing the number of colors in the
              theme, making it easier for each brand to customize. Along with
              simplifying the color scheme, I also enhanced accessibility by
              ensuring proper contrast ratios and improved typography
              consistency for better readability. <br />
              <br />
              Components were optimized to a reusable standard, allowing changes
              to be made in one place and propagate consistently throughout the
              system.
            </Text>
          </Box>
        </Flex>

        <Carousel img1={img1} img2={img2} img3={img3} img4={img4} img5={img5} />

        <Flex
          flexWrap="wrap"
          justifyContent="center"
          variant="container"
          id="project-content"
          mt={[4, 5, 6]}
        >
          <Box width={[1 / 1, 12 / 12]} variant="containerBigTitle">
            <ProjectFadeInTitle
              word1={"Improved UX"}
              word2={"for our custmers"}
              word2ClassName={"outline"}
              className={"uppercase"}
            />
          </Box>
          <Box variant="containerTxt">
            <Heading as="h3" variant="catHeading">
              The Oportunity
            </Heading>
            <Text as="p" variant="paragraphXL" mb={[4]}>
              Elevate the future of Travelbase by transforming its
              fastest-growing booking platform.
            </Text>
            <Heading as="h3" variant="catHeading">
              Our goals
            </Heading>
            <Text as="p" mb={[4, 5, 6]}>
              In addition to revamping the design systems, we aim to enhance the
              platform by adding an agenda, an activity planner, optimized
              accommodation listings, holiday packages, and improved search,
              filter, and sort functionalities.
            </Text>
          </Box>
        </Flex>

        <Flex flexWrap="wrap" justifyContent="center" variant="container">
          <Box width={[1 / 1, 1 / 1, 1 / 1, 6 / 12]} mb={[4, 5]}>
            <Box>
              <StaticImage
                src="../../../img/oberon/oberon-travelbase-UX-homepage-search.jpg"
                alt="Travelbase - Activities Planner"
              />
            </Box>
            <Box variant="containerTxt">
              <Text as="span" variant="caption">
                User research revealed that visitors struggled to easily find
                accommodations. By{" "}
                <strong>adding a homepage search feature on hompeage</strong>{" "}
                and improving interaction, we significantly improved access to
                accommodations.
              </Text>
            </Box>
          </Box>
          <Box width={[1 / 1, 1 / 1, 1 / 1, 6 / 12]}>
            <Box>
              <StaticImage
                src="../../../img/oberon/oberon-travelbase-UX-activities-planner.jpg"
                alt="Travelbase - Activities Planner"
              />
            </Box>
            <Box variant="containerTxt">
              <Text as="span" variant="caption">
                We introduced a new section called "Agenda" to enable our
                clients to add activities. To support this, we designed the{" "}
                <strong>activities planner</strong> to help users organize their
                holidays effectively.
              </Text>
            </Box>
          </Box>
          <Box width={[1 / 1, 1 / 1, 1 / 1, 12 / 12]}>
            <Box>
              <StaticImage
                src="../../../img/oberon/oberon-travelbase-search-hit-flow.jpg"
                alt="Travelbase - Activities Planner"
              />
            </Box>
            <Box variant="containerTxt">
              <Text as="span" variant="caption">
                <strong>Search is at the core of our platform.</strong> To make
                it more user-centric, we redesigned it and enhanced its UX by
                providing better guidance. This helps users understand their
                current location, what actions to take, and ensures they don't
                forget essential information about their reservations.
              </Text>
            </Box>
          </Box>
        </Flex>
        <CompanyProjects title="OBERON" />
        <ProjectTitleListing />
        <BackToTop />
        <Footer />
      </main>
    </Layout>
  );
}
export default ProjectTest;
